/* Estilos globales para el editor */
/* Estilos globales para el editor */

.tiptap-editor {
    border: 1px solid #ccc; /* Borde claro */
    border-radius: 8px; /* Bordes redondeados */
    padding: 8px;
    background-color: #ffffff; /* Fondo blanco */
    min-height: 100px; /* Altura mínima */
    font-size: 14px;
    line-height: 1.3;
    max-height: 400px; /* Altura máxima para evitar que ocupe demasiado espacio */
    overflow-y: auto; /* Permitir scroll interno */

    > * + * {
        margin-top: 0.75em;
    }

    /* Evitar bordes adicionales cuando el editor está activo */
    outline: none;
    &.is-active {
        border-color: #007BFF; /* Color del borde cuando el editor está activo */
    }
}

/* Si persiste el borde adicional, intenta con esto */
.tiptap-editor * {
    border: none !important; /* Elimina cualquier borde de elementos internos */
    outline: none !important; /* Elimina cualquier contorno de elementos internos */
}



.mention {
    border: 1px solid #007BFF; /* Color del borde de la mención */
    border-radius: 4px;
    padding: 0.1rem 0.3rem;
    background-color: #e6f7ff; /* Fondo de la mención */
}

/* Estilos para la lista de menciones */
.suggestion-list {
    background: #fff; /* Fondo blanco */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra para el cuadro */
    padding: 0.5rem;
    color: #333; /* Color de texto */
    border: 1px solid #ccc; /* Borde gris claro */
    z-index: 9999; /* Asegúrate de que el popup esté por encima de otros elementos */
}

.suggestion-item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px; /* Bordes redondeados */
    padding: 0.5rem;
    cursor: pointer;
    text-align: left;
    width: 100%;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f5f5f5; /* Fondo gris claro al pasar el mouse */
    }

    &.is-selected {
        background-color: #f0f0f0; /* Fondo gris claro para la selección */
        border-color: #007BFF; /* Borde azul para la selección */
    }
}

.items {
    background: #fff; /* Fondo blanco */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra para el cuadro */
    padding: 0.5rem;
    color: #333; /* Color de texto */
    border: 1px solid #ccc; /* Borde gris claro */
    z-index: 9999; /* Asegúrate de que el popup esté por encima de otros elementos */
}

.item {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px; /* Bordes redondeados */
    padding: 0.5rem;
    cursor: pointer;
    text-align: left;
    width: 100%;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f5f5f5; /* Fondo gris claro al pasar el mouse */
    }

    &.is-selected {
        background-color: #f0f0f0; /* Fondo gris claro para la selección */
        border-color: #007BFF; /* Borde azul para la selección */
    }
}

/* Estilos para sobrescribir los predeterminados de tippy.js */
.tippy-box {
    background-color: transparent !important; /* Elimina el fondo oscuro */
    box-shadow: none !important; /* Elimina la sombra del popup */
    border: none !important; /* Elimina cualquier borde innecesario */
}

.tippy-content {
    padding: 0 !important; /* Elimina el padding adicional */
}
