/* calendarStyles.css */

/* Estilos generales del calendario */
.rbc-calendar {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.rbc-calendar .rbc-event-content {
  font-size: 10px !important;
}

.rbc-event-label {
  font-size: 9px !important;
}

/* Estilos de la barra de herramientas */
.rbc-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.rbc-btn-group {
  display: flex;
}

.rbc-btn-group button {
  padding: 8px 12px;
  border: 1px solid #c6c6c6;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.rbc-btn-group button.rbc-active,
.rbc-btn-group button:hover {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.rbc-toolbar-label {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Estilos de la vista de tiempo */
.rbc-time-view {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 16px !important;
  box-sizing: border-box;
}

.rbc-time-header, .rbc-time-content {
  margin: 8px 4px;
  padding: 8px;
}

.rbc-time-header {
  margin: 0 !important;
  border: none !important;
}

.rbc-time-content {
  margin: 0 !important;
}

.rbc-time-header-content {
  width: 100%;
}

/* Estilos de los encabezados */
.rbc-header .rbc-button-link {
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
}

/* Estilos para el día actual */
.rbc-today {
  background-color: #e3f2fd;
}

.rbc-today .rbc-day-bg {
  background-color: transparent !important;
}

.rbc-header.rbc-today {
  background-color: #f0f0f0 !important;
}

/* Estilos de los eventos */
.rbc-event {
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  font-size: 6px !important;
}

.rbc-day-slot .rbc-event, 
.rbc-day-slot .rbc-background-event {
  border: 2px solid #ffffff;
  display: flex;
  max-height: 100%;
  min-height: 20px;
  flex-flow: column wrap;
  align-items: flex-start;
  overflow: hidden;
  position: absolute;
}

/* Ocultar eventos de todo el día */
.rbc-allday-cell {
  display: none;
}

/* Estilos para la vista de mes */
.rbc-month-view {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  padding: 16px !important;
  box-sizing: border-box;
}

/* Eliminar fondos y bordes innecesarios */
.rbc-time-header,
.rbc-time-header .rbc-label,
.rbc-time-header .rbc-time-header-content,
.rbc-time-header .rbc-row-bg,
.rbc-time-header .rbc-day-bg {
  background-color: transparent !important;
}

.rbc-time-header-content,
.rbc-time-header-content .rbc-row,
.rbc-time-header-content .rbc-header,
.rbc-time-header-content .rbc-allday-cell,
.rbc-time-header-content .rbc-row-bg,
.rbc-time-header-content .rbc-day-bg {
  border: none !important;
}

/* Ajustes de tamaño de fuente */
.rbc-time-gutter .rbc-label {
  font-size: 10px !important;
}

/* Estilos para interacciones */
[role="button"]:active,
.rbc-addons-dnd-resizable:active,
.rbc-event.rbc-selected {
  background-color: #454545 !important;
  color: #fff !important;
}

/* Estilos para la vista de agenda */
.rbc-agenda-view {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  padding: 16px !important;
  box-sizing: border-box;
}

/* Personalización para la tabla de la vista de agenda */
.rbc-agenda-table {
  border: none !important;
  border-spacing: 0 !important;
  border-collapse: separate !important;
}

/* Efecto hover para las filas de la tabla de agenda */
.rbc-agenda-table tbody tr:hover {
  background-color: #f0f0f0; /* Gris claro al pasar el mouse */
  transition: background-color 0.3s ease; /* Transición suave */
}

.rbc-timeslot-group {
  min-height: 50px;
}

/* Estilo para citas canceladas */
.rbc-event.canceled {
  background-color: #D3D3D3 !important;
  color: #000000 !important;
}

/* Asegurarse de que el estilo se aplique incluso cuando el evento está seleccionado */
.rbc-selected.canceled {
  background-color: #BEBEBE !important;
}
