/* index.css */
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}

@media (max-width: 960px) {
  /* Ajustes para pantallas medianas y pequeñas */
  .MuiDrawer-paper {
    width: 240px;
  }

  .MuiAppBar-root {
    z-index: 1300;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }
}
